
:root {
    --primary-color: #3699FF;
    --secondary-color: #6c757d;
}

.custom-button {
    color: #3699FF;
    background-color: #fff;
    border: 1px solid #3699FF;
    padding: 5px 15px;
    border-radius: 0.25rem;
    transition: 0.3s;
}
.custom-button:hover {
    background-color: #3699FF;
    color: #fff;
}

.custom-button.secondary {
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
}
.custom-button.secondary:hover {
    background-color: var(--secondary-color);
    color: #fff;
}

.custom-input {
    padding: 5px 10px;
    width: 100%;
    font-family: 'Be Vietnam Pro', sans-serif!important;
    border: 1px solid #3699FF;
    border-radius: 0.25rem;
    transition: 0.3s;
}
.custom-input:focus {
    outline: none;
    box-shadow: 0px 0px 3px 0px #3699FF;
}

.custom-table {
    width: 100%;
}
.custom-table th, .custom-table td {
    padding: 5px;
}
.custom-table tr {
    border-bottom: 1px solid #0A1551;
}
.custom-table tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
}
.custom-table tbody tr:last-child {
    border-bottom: none;
}


.custom-modal-layout {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    z-index: 10;
}
.custom-modal-layout.show {
    display: flex;
}
.custom-modal {
    width: 100%;
    max-width: 600px;
    box-shadow: 0px 0px 5px 0px var(--primary-color);
    background-color: #fff;
    border-radius: 0.5rem;
}
.custom-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid var(--primary-color);
}
.custom-modal-bottom {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid var(--primary-color);
}
.custom-modal-body {
    padding: 10px 20px;
}