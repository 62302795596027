@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.be-vn-pro-font {
    font-family: 'Be Vietnam Pro', sans-serif!important;
}
.raleway-font {
    font-family: 'Raleway', sans-serif!important;
}
.roboto-font {
    font-family: 'Roboto', sans-serif!important;
}
*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
	width: 0px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 0px solid #555555;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
h1,h2,h3,h4,h5,h6,p {
    margin: 0px;
    color: rgba(10, 21, 81, 1);
}
input {
    font-family: 'Epilogue', sans-serif!important;
}
#root {
    height: 100%;
    overflow: auto;
}
:root {
    --primary-color: #3699FF;
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: 'Be Vietnam Pro', sans-serif!important;
    overflow: auto;
    font-size: 13px;
    background-color: rgba(236, 236, 236, 0.2);
}
.app {
    width: 100%;
    height: 100%;
    overflow: auto;
}
.login-wrapper {
    width: 100%;
    height: 100%;
    background-image: url('../../public/imgs/login-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.login-layout {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-form {
    width: 100%;
    max-width: 600px;
    padding: 40px 30px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-form img {
    max-width: 30%;
}
.login-form h2 {
    margin-top: 15px;
    font-weight: 700;
    color: #0A1551;
}
.login-form h5{
    margin-top: 10px;
    color: #0A1551;
    margin-bottom: 20px;
}
.login-form input {
    width:  100%;
    margin-top: 10px;
    padding: 8px 15px;
    border: 1px solid #E8F0FE;
    background-color: #E8F0FE;
    border-radius: 100px;
    color: #3699FF;
    font-size: 16px;
    transition: 0.3s;
}
.login-form input:focus {
    outline: none;
    border: 1px solid #3699FF;
    box-shadow: #3699FF 0px 0px 2px 0px ;
}
.login-form input::placeholder {
    color: #96B6EFDD;
}
.login-form button {
    margin-top: 15px;
    width: 100%;
    padding: 10px;
    font-weight: 700;
    border: none;
    background-color: #3699FF;
    color: #fff;
    border-radius: 100px;
    opacity: 1;
    transition: 0.3s;
}
.login-form button:hover {
    opacity: 0.75;
}
header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    background-color: #fff;
    z-index: 5;
}
header .header-top, header .header-bottom {
    width: 100%;
    padding: 20px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    padding-left: 100px;
}
header .header-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.left-nav-wrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    background-color: #3699FF;
    z-index: 5;
}
.left-nav-header {
    padding: 5px;
    text-align: center;
    border-bottom: 1px solid #FAFCFF;
}
.left-nav-header img {
    max-width: 60px;
}
.left-nav-body {
    height: calc(100% - 71px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.left-nav-list {
    padding: 20px;
}
.left-nav-item {
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 0.25rem;
    border: 1px solid transparent;
    transition: 0.3s;
}
.left-nav-item .icon {
    color: #fff;
    font-size: 20px;
    margin-right: 0px;
    transition: 0.5s;
    width: 30px;
    text-align: center;
}
.left-nav-item .title {
    color: #fff;
    font-weight: 500;
    overflow: hidden;
    transition: 1s;
    max-width: 0px;
    white-space: nowrap;
}
.left-nav-wrapper:hover .left-nav-item .title {
    max-width: 170px;
}
.left-nav-item.active {
    background-color: #fff;
    box-shadow: 0px 1px 8px 0px #00000040;
}
.left-nav-item.active .title, .left-nav-item.active .icon {
    color: #3699FF;
}
.left-nav-item:not(.active):hover {
    border: 1px solid #fff;
}
.left-nav-bottom {
    padding: 5px;
    border-top: 1px solid #FAFCFF;
    font-size: 20px;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.header-bottom-action {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.main-content {
    width: calc(100% - 130px);
    margin-left: 110px;
    margin-top: 160px;
    padding: 20px;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px #00000040;
}
.right-slide-tab {
    box-shadow: 0px 0px 4px 0px #00000040;
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    background-color: #fff;
    white-space: nowrap;
    max-width: 0px;
    overflow: hidden;
    transition: 0.5s;
    z-index: 5;
}
.right-slide-tab.active {
    max-width: 300px;
}
.right-slide-tab-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 20px;
    padding-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
}
.right-slide-tab-wrapper .close-icon {
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
    font-size: 20px;
}
._loading_overlay_wrapper {
    position: fixed!important;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 11;
}
.form-group {
    margin-bottom: 10px;
}